import styled from "styled-components";

const Details = styled.div`
  height: 60rem;
  width: 30rem;
`;

const NewFileDetails = () => {
  return <Details>meow</Details>;
};

export default NewFileDetails;
