import styled from "styled-components";

const Presets = styled.div`
  width: 80rem;
  background-color: var(--popup-new-inner-bg);
`;

const NewFilePresets = () => {
  return <Presets>meow</Presets>;
};

export default NewFilePresets;
